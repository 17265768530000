import React from "react";
import Loadable from "react-loadable";
import withRoleRequired from '../../lib/withRoleRequired';
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Spinner from "../../components/deliberative-survey/Spinner";

const LoadableSummaryCharts = Loadable({
  loader: () => import("../../components/deliberative-survey/SummaryCharts"),
  loading() {
    return (
      <>
        <div />
        <div className='col-span-5 m-auto'>
          <Spinner/>
        </div>
      </>
    );
  }
});

const Summary: React.FunctionComponent = () => (
  <Layout pageHeader="Summary Data"
    breadcrumbs={[{ label: 'Attitudes Towards Defence', link: '/deliberative-survey' }]}
    moreInfo = "deliberative-survey/summary-data"
  >
    <SEO
      keywords={[`future`, `workforce`, `modelling`, `tool`]}
      title="Attitudes Towards Defence Summary Data"
    />
    <main className="pb-8 h-full">
      <div className="mx-auto px-4 sm:px-6 lg:px-8 h-full">
        <div className = "grid grid-cols-5 gap-x-1 gap-y-0 h-full grid-rows-summary">
          <div className = 'col-span-5 pb-4'>
            This page displays summarised background information regarding the survey participants. Click on the radio buttons to see the breakdown of participants based on different demographic features. Note that data regarding children’s sex and children’s age is not available for the young adult group.
          </div>
          <LoadableSummaryCharts/>
        </div>
      </div>
    </main>

  </Layout>
)

export default withRoleRequired(Summary);


